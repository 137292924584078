import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import * as am4core from "@amcharts/amcharts4/core";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import AOS from "aos";

import "aos/dist/aos.css";

import { ParallaxProvider } from "react-scroll-parallax";
import { TranslationProvider } from "../Context/Translation";

import {
  REACT_APP_AM_CHARTS_LICENCE,
  REACT_APP_AM_CHARTS_MAPS_LICENCE,
} from "../Constants/ChartConstants";

import { PAGE } from "../Constants/RouteConstants";

const LayoutContainer = React.lazy(() => import("./LayoutContainer"));
const PageContainer = React.lazy(() => import("./PageContainer"));
const PageMissing = React.lazy(() => import("../Components/Debug/PageMissing"));
const SearchContainer = React.lazy(() => import("./SearchContainer"));

am4core.addLicense(REACT_APP_AM_CHARTS_LICENCE);
am4core.addLicense(REACT_APP_AM_CHARTS_MAPS_LICENCE);

am4core.useTheme(am4themesAnimated);

AOS.init({
  once: true,
  // startEvent: "load",
  // startEvent: "DOMContentLoaded",
  startEvent: "PageLoaded",
  // disable: "mobile",
  offset: 100,
  duration: 1000,
  delay: 250,
  easing: "ease-out-back",
  anchorPlacement: "top-bottom",
  disableMutationObserver: true,
});

/* eslint-disable react/jsx-props-no-spreading */
const RootContainer = () => {
  return (
    <React.Suspense fallback={<></>}>
      <BrowserRouter>
        <TranslationProvider>
          <ParallaxProvider>
            <LayoutContainer>
              <section id="main">
                <Switch>
                  <Route exact path="/error" component={PageMissing} />
                  <Route
                    exact
                    path="/s"
                    render={(routerProps) => (
                      <SearchContainer
                        {...routerProps}
                        key={`key_${routerProps.location.search || Math.random()}`}
                      />
                    )}
                  />
                  <Route
                    path={PAGE}
                    render={(routerProps) => (
                      <PageContainer
                        {...routerProps}
                        key={`key_${routerProps.location.pathname || Math.random()}`}
                      />
                    )}
                  />
                </Switch>
              </section>
            </LayoutContainer>
          </ParallaxProvider>
        </TranslationProvider>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootContainer;
